import MicroModal from "micromodal";
import anime from "animejs/lib/anime.es.js";

window.addEventListener("DOMContentLoaded", () => {
  const headerTimeline = anime.timeline({
    easing: "linear",
    duration: 750
  });

  headerTimeline
    .add({
      targets: ".header__logo",
      translateY: ["100%", 0],
      opacity: [0, 1]
    })
    .add({
      targets: ".header-from-top",
      translateY: ["-100%", 0],
      opacity: [0, 1]
    })
    .add({
      targets: ".footer__menu-list",
      translateY: ["100%", 0],
      opacity: [0, 1]
    });

  anime({
    targets: ".fadeInEffect",
    opacity: 1,
    translateY: [100, 0],
    duration: 1000,
    easing: "linear",
    autoplay: true,
    delay: 750
  });

  const textAnimtion = anime({
    targets: ".animatatedText span",
    translateY: ["50%", 0],
    opacity: [0, 1],
    duration: 500,
    easing: "easeInCubic",
    autoplay: false,
    delay: anime.stagger(50, { start: 750 })
  });

  textAnimtion.play();

  const switchableElements = document.querySelectorAll(".switchable-elements");

  if (switchableElements) {
    setInterval(function() {
      switchableElements.forEach(element => {
        const el = element.querySelectorAll(".switchable-elements__item");
        el.forEach(item => {
          if (item.classList.contains("is-disabled")) {
            item.classList.remove("is-disabled");
          } else {
            item.classList.add("is-disabled");
          }
        });
      });
      anime({
        targets: ".animatatedText span",
        translateY: ["50%", 0],
        opacity: [0, 1],
        duration: 500,
        easing: "easeInCubic",
        delay: anime.stagger(50)
      });
    }, 7000);
  }

  MicroModal.init();

  const toggleMenu = document.querySelectorAll('[data-toggle="menu"]');
  const menuLinks = document.querySelectorAll(".header__menu-link");
  const careersList = document.querySelectorAll(".careers-content__subtitle");

  if (menuLinks) {
    menuLinks.forEach(element => {
      if (
        window.location.pathname ===
        element.href.split(window.location.origin).pop()
      ) {
        element.classList.add("is-current");
      }
    });
  }

  if (toggleMenu) {
    toggleMenu.forEach(element => {
      element.addEventListener("click", event => {
        event.preventDefault();

        document.body.classList.toggle("show-menu");
      });
    });
  }

  if (careersList) {
    careersList.forEach(element => {
      element.addEventListener("click", event => {
        event.preventDefault();

        element.parentNode.classList.toggle("is-expanded");
      });
    });
  }
});

// resize window
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});
